import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import { useURLSearchParams } from 'src/client/helpers/useURLSearchParams';
import { Modal } from '../Modal/Modal';
import MetCart from '../../pages/UpsalesPage/MetCart';
import { apiUrls, ORDER_STATUS, routerPaths } from '../../helpers/constants';
import { BillerById, SubscriptionType } from '../../../shared/constants/billers';
import { delay, getCategoryIdByCampaignName, resolveProduct } from '../../helpers/utils';
import { ApiClient, useAppData } from '../../helpers';
import { useAnalyticsSignals } from '../Analytics/useAnalyticsSignals';

export const UpsaleMetCart = styled(MetCart)`
  min-height: 100vh;

  @media (min-width: 992px) {
    min-height: auto;

    &.has-iframe {
      min-height: 50vh;
    }
  }
`;

function purchaseReducer(state, action) {
  let { errored, purchased } = state;

  if (action.type === 'SET') {
    if (action.errors) {
      errored.push(...action.errors);
    }

    if (action.purchases) {
      purchased.push(...action.purchases);
    }
  }

  if (action.type === 'RESET') {
    errored = [];
    purchased = [];
  }

  return {
    errored: [...new Set(errored)],
    purchased: [...new Set(purchased)],
  };
}

const eCommerceSourceMap = {
  upsale: 'Upsale Page',
  upgrade: 'Upgrade Page',
};

export function PurchaseModal(props) {
  const { onClick, onComplete, onError, products, showPurchaseModal, type, source } = props;

  const { networkSites } = useAppData();
  const { addToast } = useToasts();
  const searchParams = useURLSearchParams();
  const { signalAnalyticsUpgrade } = useAnalyticsSignals();
  const [iframeURL, setIframeURL] = useState('');
  const [redirectURLs, setRedirectURLs] = useState({});
  const [postURL, setPostURLs] = useState('');
  const [postData, setPostData] = useState('');
  const [{ errored, purchased }, dispatch] = useReducer(purchaseReducer, { errored: [], purchased: [] });
  const [cart, setCart] = useState({});
  const [purchasing, setPurchasing] = useState(false);
  const [purchasesComplete, setPurchasesComplete] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [shoppingCart, setShoppingCart] = useState([]);
  const windowRef = useRef(null);
  const campaign = searchParams.get('campaign');

  // changing to useEffect here since there has been a change to how we manage the cart data in the session storage
  // once `onComplete` fires way down below after the purchases have finished, the cart data is emptied
  // this causes the updated session data (empty array) to come back to this component erasing the cart data from the modal
  useEffect(() => {
    if (products.length > 0) {
      setShoppingCart(
        products.map((product) => ({
          domain: product.domain,
          name: product.site,
          UUID: product.siteUUID,
        })),
      );
    }
  }, [products]);

  const showItem = useCallback((cartItem) => {
    if (cartItem && cartItem.postURL) {
      setPostURLs(cartItem.postURL);
      setPostData(cartItem.postData);
    } else if (cartItem && cartItem.iframeURL) {
      setIframeURL(cartItem.iframeURL);
    } else if (cartItem && cartItem.redirectURL) {
      setRedirectURLs((prevState) => ({ ...prevState, [cartItem.product.siteUUIDs[0]]: cartItem.redirectURL }));
    } else {
      // TODO error
    }
  }, []);

  const chargeByPrevious = useCallback(async (cartItem, cartData) => {
    const api = new ApiClient();
    const { body } = await api.post(apiUrls.post.chargeByPrevious, {
      data: {
        cartItemUUID: cartItem.cartItemUUID,
        billerID: cartData.billerID,
      },
    });

    return {
      success: body.success,
      iframeURL: body.iframeURL,
      redirectURL: body.redirectURL,
      postURL: body.postURL,
      postData: body.postData,
      subscriptionID: body.subscriptionID,
    };
  }, []);

  const processResult = useCallback(async (data, currentItem) => {
    if (!data || data.cartUUID !== currentItem.cartUUID) {
      return;
    }

    const processedPurchased = [];
    const processedErrored = [];

    if (Array.isArray(data.cartItems)) {
      const processedCartItems = data.cartItems.map((cartItem) => {
        let status = cartItem.status || ORDER_STATUS.UNPROCESSED;

        if (cartItem.cartItemUUID === currentItem.cartItemUUID || `${currentItem.all}` === '1') {
          if (currentItem.success) {
            status = ORDER_STATUS.SUCCESS;
            processedPurchased.push(...cartItem.product.siteUUIDs);
          } else if (currentItem.redirected) {
            status = ORDER_STATUS.REDIRECT;
          } else {
            status = ORDER_STATUS.ERROR;
            processedErrored.push(...cartItem.product.siteUUIDs);
          }
        }

        return {
          ...cartItem,
          status,
        };
      });

      if (currentItem.success && currentItem.cartItemUUID) {
        const api = new ApiClient();

        await delay(5000);

        try {
          const {
            body: { invoices },
          } = await api.get(apiUrls.get.invoice, {
            params: {
              cartItemUUID: currentItem.cartItemUUID,
              extSubscriptionID: currentItem.subscriptionID,
            },
          });

          setInvoiceData((prevState) => [...prevState, ...invoices]);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Failed to process invoice data');
        }
      }

      if (processedPurchased.length > 0) {
        dispatch({ type: 'SET', purchases: processedPurchased });
      }

      if (processedErrored.length > 0) {
        dispatch({ type: 'SET', errors: processedErrored });
      }

      setCart((prevState) => ({ ...prevState, cartItems: processedCartItems }));

      if (processedCartItems.every((item) => item.status > 0)) {
        setPurchasesComplete(true);
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (cart.cartItems && cart.cartItems.some((item) => item.status === ORDER_STATUS.UNPROCESSED)) {
        const nextItem = cart.cartItems.find((cartItem) => cartItem.status === ORDER_STATUS.UNPROCESSED);

        let success;
        let subscriptionID;
        if (nextItem) {
          if (cart.canChargeByPrevious) {
            try {
              const response = await chargeByPrevious(nextItem, cart);
              if (!response.success) {
                if (response.iframeURL) {
                  nextItem.iframeURL = response.iframeURL;
                }

                if (response.redirectURL) {
                  nextItem.redirectURL = response.redirectURL;
                }

                if (response.postURL) {
                  nextItem.postURL = response.postURL;
                  nextItem.postData = response.postData;
                }

                if (!response.iframeURL && !response.redirectURL && !response.postURL) {
                  throw new Error('CBP_FAIL_NO_FORM');
                }
              } else {
                success = response.success;
                subscriptionID = response.subscriptionID;
              }
            } catch (err) {
              // eslint-disable-next-line no-console
              console.warn('Falling back to original purchase form', { message: err.message });
            }
          }

          if (!success) {
            if (cart.canChargeByPrevious) {
              const { itemBrand, itemName } = resolveProduct(nextItem.product, networkSites);

              window.dataLayer.push({
                event: 'begin_checkout',
                ecommerce: {
                  source: eCommerceSourceMap[type],
                  value: parseFloat(nextItem.product.initialPrice),
                  tax: '',
                  currency: nextItem.product.currency,
                  payment_method: BillerById[cart.billerID],
                  cbp: 0,
                  items: [
                    {
                      item_name: itemName,
                      item_id: nextItem.product.productUUID,
                      item_brand: itemBrand,
                      item_category: 'subscription',
                      quantity: 1,
                      price: parseFloat(nextItem.product.initialPrice),
                    },
                  ],
                },
              });
              window.dataLayer.push({
                event: 'begin_checkout_ua',
                ecommerce: {
                  currency: nextItem.product.currency,
                  checkout: {
                    actionField: {
                      step: 1,
                      option: BillerById[cart.billerID],
                      source: eCommerceSourceMap[type],
                      cbp: 0,
                    },
                    products: [
                      {
                        name: itemName,
                        id: nextItem.product.productUUID,
                        brand: itemBrand,
                        category: 'subscription',
                        quantity: 1,
                        price: parseFloat(nextItem.product.initialPrice),
                      },
                    ],
                  },
                },
              });
            }

            showItem(nextItem);
          }

          if (success || nextItem.redirectURL || nextItem.postURL) {
            await processResult(cart, {
              cartItemUUID: nextItem.cartItemUUID,
              cartUUID: cart.cartUUID,
              subscriptionID,
              success,
              redirected: !!(nextItem.redirectURL || nextItem.postURL),
            });
          }
        }
      }
    })();
  }, [cart, chargeByPrevious, networkSites, processResult, showItem, type]);

  const createOrder = useCallback(async () => {
    const api = new ApiClient();

    // fallback for the first order, then use the cart biller
    const [{ billerID }] = products;

    try {
      const orderData = {
        billerID: cart.billerID || billerID,
        products,
        type,
        campaign,
        source,
      };

      const { body } = await api.post(apiUrls.post.createOrder, { data: orderData });

      if (!body || body.cartItems.length === 0) {
        throw new Error(body.errorText ? 'TRIAL_ABUSE' : 'ORDER_FAILURE');
      }

      setCart(body);

      window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          source: eCommerceSourceMap[type],
          value: body.cartItems.reduce((acc, val) => acc + parseFloat(val.product.initialPrice), 0),
          tax: '',
          currency: body.cartItems[0].currency,
          payment_method: BillerById[body.billerID],
          cbp: body.canChargeByPrevious ? 1 : 0,
          items: body.cartItems.map((cartItem) => {
            const { product } = cartItem;

            const { itemBrand, itemName } = resolveProduct(product, networkSites);

            return {
              item_name: itemName,
              item_id: product.productUUID,
              item_brand: itemBrand,
              item_category: 'subscription',
              quantity: 1,
              price: parseFloat(product.initialPrice),
            };
          }),
        },
      });
      window.dataLayer.push({
        event: 'begin_checkout_ua',
        ecommerce: {
          currency: body.cartItems[0].currency,
          checkout: {
            actionField: {
              step: 1,
              option: BillerById[body.billerID],
              source: eCommerceSourceMap[type],
              cbp: body.canChargeByPrevious ? 1 : 0,
            },
            products: body.cartItems.map((cartItem) => {
              const { product } = cartItem;

              const { itemBrand, itemName } = resolveProduct(product, networkSites);

              return {
                name: itemName,
                id: product.productUUID,
                brand: itemBrand,
                category: 'subscription',
                quantity: 1,
                price: parseFloat(product.initialPrice),
              };
            }),
          },
        },
      });
    } catch (error) {
      if (error.message === 'TRIAL_ABUSE') {
        addToast(
          'Trial memberships on one or more selected sites have been used previously. Please choose another subscription plan.',
          { appearance: 'error', autoDismiss: false },
        );
      } else {
        addToast('Unable to create your order, please try again later', { appearance: 'error', autoDismiss: false });
      }
      onError();
      const erroredItems = [];
      products.forEach((product) => erroredItems.push(...product.siteUUIDs));
      dispatch({ type: 'SET', errors: erroredItems });
    }
  }, [addToast, cart, networkSites, onError, products, type, campaign, source]);

  const startPurchases = useCallback(async () => {
    setPurchasing(true);

    if (products.length > 0) {
      await createOrder();
    }
  }, [createOrder, products]);

  useEffect(() => {
    if (!purchasing && showPurchaseModal) {
      startPurchases();
    }
  }, [purchasing, showPurchaseModal, startPurchases]);

  useEffect(() => {
    const handleMessage = (e) => {
      if (
        e &&
        e.data &&
        e.data.source === 'onCompletePage' &&
        e.origin === `${window.location.protocol}//${window.location.host}`
      ) {
        // NOTE: you get messages in from react dev tools... filter by source property
        if (windowRef && windowRef.current) {
          windowRef.current.close();
        } else if (e.source) {
          e.source.close();
        }

        setIframeURL(null);
        processResult(cart, e.data);
      }
    };

    window.addEventListener('message', handleMessage, false);

    return function cleanup() {
      window.removeEventListener('message', handleMessage);
    };
  }, [cart, windowRef, processResult]);

  useEffect(() => {
    if (purchasesComplete && invoiceData.length > 0) {
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: invoiceData[0].cartItemUUID,
          source: eCommerceSourceMap[type],
          value: invoiceData.reduce((acc, val) => acc + parseFloat(val.amount), 0),
          tax: invoiceData.reduce((acc, val) => acc + parseFloat(val.vatTax), 0),
          currency: invoiceData[0].currency,
          payment_method: BillerById[invoiceData[0].billerID],
          cbp: cart.canChargeByPrevious ? 1 : 0,
          items: invoiceData.map((item) => {
            const { itemBrand, itemName } = resolveProduct(item, networkSites);

            return {
              item_name: itemName,
              item_id: item.productUUID,
              item_brand: itemBrand,
              item_category: item.isPPS ? 'pps' : 'subscription',
              quantity: 1,
              price: parseFloat(item.amount),
            };
          }),
        },
      });
      window.dataLayer.push({
        event: 'purchase_ua',
        ecommerce: {
          currency: invoiceData[0].currency,
          purchase: {
            actionField: {
              id: invoiceData[0].cartItemUUID,
              source: eCommerceSourceMap[type],
              tax: invoiceData.reduce((acc, val) => acc + parseFloat(val.vatTax), 0),
              revenue: invoiceData.reduce((acc, val) => acc + parseFloat(val.amount), 0),
              cbp: cart.canChargeByPrevious ? 1 : 0,
              options: BillerById[invoiceData[0].billerID],
            },
            products: invoiceData.map((item) => {
              const { itemBrand, itemName } = resolveProduct(item, networkSites);

              return {
                name: itemName,
                id: item.productUUID,
                brand: itemBrand,
                category: item.isPPS ? 'pps' : 'subscription',
                quantity: 1,
                price: parseFloat(item.amount),
              };
            }),
          },
        },
      });

      signalAnalyticsUpgrade({
        categoryId: getCategoryIdByCampaignName(campaign),
      });

      setInvoiceData([]);
    }
  }, [cart.canChargeByPrevious, invoiceData, networkSites, purchasesComplete, signalAnalyticsUpgrade, type, campaign]);

  useEffect(() => {
    (async () => {
      if (purchasesComplete) {
        const foundFreeSites = products.filter((item) => item.freeSite);

        if (foundFreeSites.length > 0) {
          const purchasedItems = [];
          const erroredItems = [];
          const productSiteUUIDs = products
            .filter((item) => item.subscriptionTypeID === SubscriptionType.Lifetime)
            .reduce((acc, value) => [...acc, ...value.siteUUIDs], []);

          const lifetimeProducts = [...new Set(productSiteUUIDs)];

          let filteredFreeSites = foundFreeSites;
          if (foundFreeSites.length > lifetimeProducts.length) {
            filteredFreeSites = foundFreeSites.slice(0, lifetimeProducts.length);
            erroredItems.push(...foundFreeSites.slice(filteredFreeSites.length).map((item) => item.siteUUID));
          }

          filteredFreeSites = filteredFreeSites.filter(
            (item) => !purchased.includes(item.siteUUID) && !errored.includes(item.siteUUID),
          );

          if (filteredFreeSites.length > 0) {
            const purchasedLifetimeCount = purchased.filter((item) => lifetimeProducts.includes(item)).length;

            if (purchasedLifetimeCount > 0) {
              const finalProducts = filteredFreeSites.slice(0, purchasedLifetimeCount);
              const finalSiteUUIDs = finalProducts.map((item) => item.siteUUID);

              try {
                const api = new ApiClient();
                const {
                  body: { siteUUIDs },
                } = await api.post(apiUrls.post.createSubscriptions, {
                  data: {
                    siteUUIDs: finalSiteUUIDs,
                  },
                });

                finalSiteUUIDs.forEach((siteUUID) => {
                  if (siteUUIDs.includes(siteUUID)) {
                    purchasedItems.push(siteUUID);
                  } else {
                    erroredItems.push(siteUUID);
                  }
                });
              } catch (error) {
                erroredItems.push(...finalSiteUUIDs);
              }
            }

            dispatch({
              type: 'SET',
              errors: erroredItems,
              purchases: purchasedItems,
            });
          }
        }
      }
    })();
  }, [errored, products, purchased, purchasesComplete]);

  useEffect(() => {
    if (purchasesComplete) {
      const querystring = new URLSearchParams();
      if (cart.cartItems) {
        querystring.set('type', 'cc');
        querystring.set('success', 'true');
        querystring.set('cartItemUUID', cart.cartItems.map((item) => item.cartItemUUID).join(','));
        querystring.set('subscriptionID', cart.cartItems.map((item) => item.subscriptionID).join(','));
        querystring.set('isMultiPurchase', cart.cartItems.length > 1);
        querystring.set('siteUUIDs', cart.cartItems.map((item) => item.siteUUID).join(','));
      }

      onComplete();
      setPurchasesComplete(false);
      window.location.assign(`${routerPaths.purchase.success}?${querystring.toString()}`);
    }
  }, [onComplete, purchasesComplete, cart]);

  useEffect(() => {
    if (!showPurchaseModal) {
      dispatch({ type: 'RESET' });
      setPurchasing(false);
    }
  }, [showPurchaseModal]);

  return (
    <Modal show={showPurchaseModal} showHeader={false}>
      <UpsaleMetCart
        iframeURL={iframeURL}
        errored={errored}
        onClick={onClick}
        purchased={purchased}
        redirectURLs={redirectURLs}
        shoppingCart={shoppingCart}
        postURL={postURL}
        postData={postData}
        upsale
      />
    </Modal>
  );
}

PurchaseModal.propTypes = {
  onComplete: PropTypes.func,
  onClick: PropTypes.func,
  onError: PropTypes.func,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      domain: PropTypes.string,
      initialPeriod: PropTypes.number,
      initialPrice: PropTypes.string,
      objectUUID: PropTypes.string,
      productUUID: PropTypes.string,
      recurringPeriod: PropTypes.number,
      recurringPrice: PropTypes.string,
      site: PropTypes.string,
      siteUUID: PropTypes.string,
      siteUUIDs: PropTypes.arrayOf(PropTypes.string),
      subscriptionTypeID: PropTypes.number,
    }),
  ),
  showPurchaseModal: PropTypes.bool.isRequired,
  source: PropTypes.oneOf(['upgrade-portal', 'members-portal', 'upsales-page', 'unknown-source']),
  type: PropTypes.oneOf(['upsale', 'upgrade']),
};

PurchaseModal.defaultProps = {
  onClick: () => {},
  onComplete: () => {},
  onError: () => {},
  products: [],
  source: 'upsales-page',
  type: 'upsale',
};
