import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { useCookies } from 'react-cookie';
import { AltPayment, CartItem } from '@tovia/man-protos/dist/types/billing.types';
import { useToasts } from 'react-toast-notifications';
import { ensure } from '@tovia/man-app-utils/lib/helpers/type-utils';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useGoogleAnalyticsABTest } from 'src/client/helpers/useGoogleAnalytics';
import { Header } from '../../components/TemplateA/Header/Header';
import { UserForm } from '../../components/UserFormSection/UserForm';
import { PaymentMethods } from '../../components/PaymentMethodSection/PaymentMethods';
import { SubscriptionPlans } from '../../components/SubscriptionPlanSection/SubscriptionPlans';
import { SecuritySection } from '../../components/SecuritySection/SecuritySection';
import { ChatWidget } from '../../components/ChatWidget/ChatWidget';
import { MainFooter } from '../../components/Footer/MainFooter';
import { Teasers } from '../PreJoinPage/Teasers';
import { mediaBreakpoints } from '../../helpers/mediaQueries';
import { Testimonials } from '../../components/Testimonials/Testimonials';
import { ApiClient, useAppData, useURLSearchParams } from '../../helpers';
import { useInfinityTagEvent } from '../../helpers/useInfinityTagEvents';
import { delay, getData, saveData } from '../../helpers/utils';
import { StoredOrderData } from '../../../shared/types/OrderResponse';
import { apiUrls, cartStorageKey, routerPaths } from '../../helpers/constants';
import { Biller, BillerById } from '../../../shared/constants/billers';
import { CreateOrderData } from '../../../shared/types/CreateOrderData';
import { useQueryParams } from '../PreJoinPage/PreJoinPage';
import { SuperAgentError } from '../../helpers/ApiClient';
import {
  pushBeginCheckoutToGoogleAnalytics,
  pushSelectItemToGoogleAnalytics,
} from '../PreJoinPage/googleAnalyticsEvents';
import { RedirectSection } from '../../components/RedirectSection/RedirectSection';
import { CascadeMessage } from '../PreJoinPage/CascadeMessage';
import { PurchaseFrame } from '../../components/Purchase/PurchaseFrame';
import UpgradeFrame from '../../components/Purchase/UpgradeFrame';
import { ChargeByPrevious } from '../../components/Purchase/ChargeByPrevious';
import { FeaturesList } from '../../components/FeaturesList/FeaturesList';
import { usePreJoinPagePrices } from './usePreJoinPagePrices';
import { PreJoinPageABProps } from '../../helpers/ABSwitchComponent';
import { ConversionType, ConversionTypes } from '../../../shared/types/userMeasurement';

export enum EmailErrors {
  INVALID_FORMAT = 'Please enter a valid e-mail',
  ALREADY_EXISTS = 'email.form.login_message',
  TOO_MANY_TRIES = 'Too many tries, please try again later',
  AVAILABILITY_GENERIC = 'There was an error checking availability, please try again',
}

export enum PasswordErrors {
  INVALID_FORMAT = 'email.form.invalid_password',
}

export type StateType<T> = [T, Dispatch<SetStateAction<T>>];

const PreJoinPageRedesign = (props: PreJoinPageABProps) => {
  const { site, testId, testPlatform, user, showLegaLRestrictionSplash } = useAppData();

  const location = useLocation();
  const history = useHistory();
  const searchParams = useURLSearchParams();
  const [cookies] = useCookies(['mam']);
  const mamPartnerID = useMemo(() => (cookies.mam ? cookies.mam.split('.')[0] : null), [cookies.mam]);
  const { affiliateID, promoCode, purchaseResponse, biller, galleryPurchase } = useQueryParams();

  const purchaseMatch = !!useRouteMatch({ exact: true, path: routerPaths.purchase.site });
  const nonNudeMatch = !!useRouteMatch({ strict: true, path: routerPaths.nonNude.path });
  const nonNudePurchaseMatch = !!useRouteMatch({ strict: true, path: routerPaths.nonNude.purchase.site });

  const { addToast } = useToasts();

  //Form
  const [email, setEmail] = useState<string>(user?.email || '');
  const [emailError, setEmailError] = useState<EmailErrors | undefined>(
    user.isUser ? undefined : EmailErrors.INVALID_FORMAT,
  );
  const [emailValidating, setEmailValidating] = useState<boolean>(false);

  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<PasswordErrors | undefined>(
    user.isUser ? undefined : PasswordErrors.INVALID_FORMAT,
  );

  const [renderEmailErrors, setRenderEmailErrors] = useState<boolean>(false);
  const [renderPasswordErrors, setRenderPasswordErrors] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  //Payment
  const [activePayment, setActivePayment] = useState<AltPayment | undefined>(undefined);
  const getPricesResponse = usePreJoinPagePrices();

  const pushGoogleAnalyticsEvent = useGoogleAnalyticsABTest('PreJoinPage');

  const [redirecting, setRedirecting] = useState(false);
  const [purchasing, setPurchasing] = useState(false);

  const [iframeUrl, setIFrameUrl] = useState('');
  const [postURL, setPostURL] = useState('');
  const [postData, setPostData] = useState('');

  // Cascade
  const [isCascading, setIsCascading] = useState(false);
  const [cascadeItem, setCascadeItem] = useState<CartItem | undefined>(undefined);
  const [cascadeCountDown, setCascadeCountDown] = useState(0);

  // Charge By Previous (CBP)
  const [itemUUID, setItemUUID] = useState<string | undefined>(undefined);
  const [canCBP, setCanCBP] = useState(false);
  const [validationRequired, setValidationRequired] = useState(false);

  const sendSubmitInfoEventOnPurchase = useInfinityTagEvent({
    name: 'Submit info button (Payment info)',
    type: 'Revenue',
    value: '0.001',
  });
  const sendSelectPlanEventOnClick = useInfinityTagEvent({
    name: 'Select plan button (create membership)',
    type: 'Acquisition',
    value: '0.001',
  });

  const GAEventABTestSentRef = useRef<boolean>(false);
  // AB test temporary measurement - valid input filled out
  useEffect(() => {
    const isValidForm = !!(!user.isUser && email && password && !emailError && !passwordError && !emailValidating);
    if (isValidForm && !GAEventABTestSentRef.current) {
      // send GA event on account info form filled out
      pushGoogleAnalyticsEvent('ab_test_account_info_filled_out', null);
      GAEventABTestSentRef.current = true;
    }

    props.signalFormEvent(isValidForm);
  }, [email, emailError, emailValidating, password, passwordError, props, user.isUser, pushGoogleAnalyticsEvent]);

  // AB test temporary measurement - initial sub attempt
  useEffect(() => {
    if (!purchasing) {
      return;
    }
    let conversionType: ConversionType = ConversionTypes.PreJoinAlternativePaymentSubmit;

    if (activePayment?.name === 'PayPal') {
      conversionType = ConversionTypes.PreJoinPayPalSubmit;
    } else if (activePayment?.name === 'Credit Card') {
      conversionType = ConversionTypes.PreJoinCreditCardSubmit;
    }

    props.signalSubEvent(conversionType);
  }, [activePayment?.name, props, purchasing]);

  const paymentTypeForApi = useMemo(() => {
    switch (activePayment?.name) {
      case 'RocketGate':
      case 'CCBill':
      case 'Epoch':
      case 'Credit Card':
      case 'PayPal':
        return 'cc';
      case 'Bitcoin':
        return 'btc';
      case 'Boleto':
        return 'boleto';
      default:
        return '';
    }
  }, [activePayment]);

  const showCartItem = useCallback(
    (cartItem) => {
      if (cartItem && cartItem.redirectURL) {
        setRedirecting(true);
        window.location.replace(cartItem.redirectURL);
      } else if (cartItem && cartItem.iframeURL) {
        setIFrameUrl(cartItem.iframeURL);
      } else if (cartItem && cartItem.postURL) {
        setPostURL(cartItem.postURL);
        setPostData(cartItem.postData);
      } else {
        addToast('Unable to present form to complete purchase, please contact support.', {
          appearance: 'error',
          autoDismiss: false,
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    const handleMessage = async (e: MessageEvent) => {
      if (
        e &&
        e.data &&
        e.data.source === 'onCompletePage' &&
        e.origin === `${window.location.protocol}//${window.location.host}`
      ) {
        // NOTE: you get messages in from React dev tools... filter by source property
        if (e.source) {
          (e.source as WindowProxy).close();
        }

        if (+e.data.errcat > 0) {
          window.location.assign('/?response=declined');
          return;
        }

        if (e.data.success) {
          window.ata?.track({ name: 2 });
        }

        const storedData = getData<StoredOrderData>(cartStorageKey);
        if (!storedData || storedData.cartUUID !== e.data.cartUUID) {
          return;
        }

        // send infinity tag event here since it's a successful purchase
        sendSubmitInfoEventOnPurchase();

        // this is here to allow the above event to finish, otherwise it will be cleaned up at the end
        await delay(500);

        if (Array.isArray(storedData.cartItems)) {
          storedData.cartItems = storedData.cartItems.map((cartItem) => {
            if (cartItem.cartItemUUID === e.data.cartItemUUID || `${e.data.all}` === '1') {
              return {
                ...cartItem,
                status: e.data.success ? 1 : 2,
                ...(!e.data.success && +e.data.billerID !== 1
                  ? {
                      cascade: Biller.RocketGate,
                    }
                  : {}),
              };
            }
            return cartItem;
          });

          const nextItem = storedData.cartItems.find((cartItem) => cartItem.status === 0);

          saveData(cartStorageKey, storedData);

          if (nextItem) {
            setItemUUID(nextItem.cartItemUUID);
            showCartItem(nextItem);
          } else {
            const querystring = new URLSearchParams(e.data);

            // forcing it to be Credit Card since ONLY RocketGate hits this handler
            querystring.set('type', 'cc');

            window.location.assign(`${routerPaths.purchase.success}?${querystring.toString()}`);
          }
        }
      }
    };

    window.addEventListener('message', handleMessage, false);

    return function cleanup() {
      window.removeEventListener('message', handleMessage);
    };
  }, [sendSubmitInfoEventOnPurchase, showCartItem, pushGoogleAnalyticsEvent]);

  const fetchCascade = useCallback(async () => {
    if (!email) {
      return;
    }

    const api = new ApiClient();

    // This is working even though the type is wrong and this has deep tendrils
    // into the rest of the app. We're not going to fix it right now.
    const products = [ensure(cascadeItem?.product)] as CreateOrderData['products'];

    const data: CreateOrderData = {
      affiliateID,
      billerID: Biller.RocketGate,
      campaign: searchParams.get('campaign') || undefined,
      cascade: true,
      crossSale: true,
      email,
      nn: nonNudeMatch,
      password,
      paymentType: activePayment?.name,
      products,
      promoCode: promoCode || undefined,
      source: 'join-page',
      type: getPricesResponse?.activeSub ? 'upgrade' : 'join',
      accountNeedsAgeVerification: showLegaLRestrictionSplash,
    };

    try {
      const { body } = await api.post(apiUrls.post.createOrder, { data });

      if (body.cartUUID) {
        saveData(cartStorageKey, { ...body, originalProduct: cascadeItem?.product });
        history.push(`${nonNudeMatch ? '/nn' : ''}/purchase`);
        setTimeout(() => {
          setIsCascading(false);
          setCascadeCountDown(0);
        }, 200);
      } else {
        addToast(body.errorText || 'Unable to create your order, please try again later', {
          appearance: 'error',
          autoDismiss: false,
        });
      }
    } catch (err) {
      addToast('Unable to create your order, please try again later', { appearance: 'error', autoDismiss: false });
    }
  }, [
    cascadeItem?.product,
    email,
    password,
    affiliateID,
    searchParams,
    nonNudeMatch,
    activePayment?.name,
    promoCode,
    getPricesResponse?.activeSub,
    history,
    addToast,
    showLegaLRestrictionSplash,
  ]);

  useEffect(() => {
    if (isCascading) {
      if (cascadeCountDown > 0) {
        setTimeout(() => {
          setCascadeCountDown(cascadeCountDown - 1);
        }, 1000);
      } else {
        fetchCascade().then(() => {
          setIsCascading(false);
        });
      }
    }
  }, [isCascading, cascadeCountDown, fetchCascade]);

  useEffect(() => {
    let storedData = getData<StoredOrderData>(cartStorageKey);
    let cascading = false;
    if (
      storedData &&
      !isCascading &&
      purchaseResponse &&
      purchaseResponse === 'declined' &&
      (biller === '3' || biller === '10')
    ) {
      const redoItem = storedData.cartItems?.find((i) => i.status === 0 || i.status === 2);
      if (!cascadeItem && redoItem) {
        cascading = true;
        setCascadeItem(redoItem);
        saveData(cartStorageKey, {
          ...storedData,
          cartItems: storedData.cartItems?.map((i) => {
            if (i.status === 0) {
              return {
                ...i,
                status: 2,
                cascade: Biller.RocketGate,
              };
            }
            return i;
          }),
        });
        storedData = getData<StoredOrderData>(cartStorageKey);
      }
    }

    if (!purchaseMatch && !nonNudePurchaseMatch && !isCascading) {
      if (purchaseResponse && purchaseResponse === 'declined') {
        if (cascadeItem && storedData && storedData.billerID !== 1) {
          setIsCascading(true);
          setCascadeCountDown(5); // 5 seconds
          return;
        }
        if (!cascadeItem && !cascading) {
          let message = `Unable to complete the purchase through the selected payment processor.${
            biller ? ' Please try a different payment method.' : ''
          }`;

          if (galleryPurchase) {
            message =
              'The purchase of your gallery was declined. Please try a different payment method or update your payment details in the account center.';
          }

          addToast(message, { appearance: 'error', autoDismiss: false });
        }
      }
    }
  }, [
    addToast,
    biller,
    nonNudePurchaseMatch,
    purchaseMatch,
    purchaseResponse,
    galleryPurchase,
    isCascading,
    cascadeCountDown,
    cascadeItem,
  ]);

  const handleOrderResponse = useCallback(
    (response) => {
      const { canChargeByPrevious } = response;
      if (response.cartItems.length > 0) {
        const [cartItem] = response.cartItems;

        setItemUUID(cartItem.cartItemUUID);
        if (cartItem.product) {
          const validation = parseFloat(cartItem.product.initialPrice) === 0 && cartItem.product.recurringPeriod === 0;
          setValidationRequired(validation);
        }

        if (!canChargeByPrevious) {
          showCartItem(cartItem);
        }
      } else {
        addToast('Unable to create your order, please try again later', {
          appearance: 'error',
          autoDismiss: false,
        });
        history.push(`${nonNudePurchaseMatch ? routerPaths.nonNude.path : '/'}`);
      }
      setCanCBP(canChargeByPrevious);
    },
    [addToast, history, nonNudePurchaseMatch, showCartItem],
  );

  useEffect(() => {
    if (purchaseMatch || nonNudePurchaseMatch) {
      const response = getData(cartStorageKey);

      if (response && Object.keys(response).length > 0) {
        handleOrderResponse(response);
      } else {
        history.push(`${nonNudePurchaseMatch ? routerPaths.nonNude.path : '/'}`);
      }
    } else {
      setIFrameUrl('');
      setCanCBP(false);
      setPurchasing(false);
    }
  }, [handleOrderResponse, history, nonNudePurchaseMatch, purchaseMatch]);

  const onSelectPlan = useCallback(
    async (priceGroupProductID: number, paymentOptionOverride?: AltPayment) => {
      let affiliateCode: string | undefined;

      if (mamPartnerID) {
        affiliateCode = `mam-${mamPartnerID}`;
      }

      if (affiliateID) {
        affiliateCode = affiliateID;
      }

      const paymentType = paymentOptionOverride || activePayment;
      const payGroup = getPricesResponse?.paymentPrices?.find((r) => r.billerID === paymentType?.billerID);
      const plans = payGroup?.subscriptionPlans;
      const product = plans?.find((p) => p.priceGroupProductID === priceGroupProductID);

      if (!product || !site || !payGroup || !activePayment) return;

      pushSelectItemToGoogleAnalytics(affiliateCode, promoCode, product, site);

      if (!user.isUser) {
        if (emailError) {
          setRenderEmailErrors(true);
        }

        if (passwordError) {
          setRenderPasswordErrors(true);
        }

        if (emailError || passwordError || emailValidating) {
          return;
        }
      }

      if (!email) {
        return;
      }

      setPurchasing(true);

      const api = new ApiClient();
      const data: CreateOrderData = {
        affiliateID,
        billerID: payGroup.billerID,
        campaign: searchParams.get('campaign') || undefined,
        crossSale: true,
        email,
        nn: nonNudeMatch,
        password,
        paymentType: activePayment.name,
        products: [product],
        promoCode: promoCode || undefined,
        source: 'join-page',
        testId,
        testPlatform,
        type: getPricesResponse?.activeSub ? 'upgrade' : 'join',
        accountNeedsAgeVerification: showLegaLRestrictionSplash,
      };

      try {
        const { body } = await api.post(apiUrls.post.createOrder, { data });

        if (body.cartUUID) {
          saveData(cartStorageKey, { ...body, originalProduct: product });

          // send infinity tag here since create order was sent
          sendSelectPlanEventOnClick();

          // send GA event about payment option
          pushGoogleAnalyticsEvent(
            'ab_test_plan_selected',
            activePayment.name === 'Credit Card' || activePayment.name === 'PayPal' ? activePayment.name : 'Other',
          );

          window.dataLayer.push({ userId: body.networkUserUUID });
          pushBeginCheckoutToGoogleAnalytics(affiliateCode, product, promoCode, BillerById[body.billerID], site);
        }

        if (!user.isUser) {
          window.location.assign(`${body.redirectURL}${location.search ? `&${location.search.substring(1)}` : ''}`);
        } else if (body.cartUUID && body.cartItems.length > 0) {
          history.push(`${nonNudeMatch ? '/nn' : ''}/purchase${location.search}`);
        } else {
          setPurchasing(false);
          addToast(body.errorText || 'Unable to create your order, please try again later', {
            appearance: 'error',
            autoDismiss: false,
          });
        }
      } catch (e: unknown) {
        setPurchasing(false);

        const error = e as SuperAgentError;

        if (error?.response?.status === 409) {
          setEmailError(EmailErrors.ALREADY_EXISTS);
        } else {
          addToast('Unable to create your order, please try again later', { appearance: 'error', autoDismiss: false });
        }
      }
    },
    [
      mamPartnerID,
      affiliateID,
      activePayment,
      getPricesResponse?.paymentPrices,
      getPricesResponse?.activeSub,
      site,
      promoCode,
      user.isUser,
      email,
      searchParams,
      nonNudeMatch,
      password,
      testId,
      testPlatform,
      emailError,
      passwordError,
      emailValidating,
      sendSelectPlanEventOnClick,
      location.search,
      addToast,
      history,
      setEmailError,
      pushGoogleAnalyticsEvent,
      showLegaLRestrictionSplash,
    ],
  );

  const onGiftCardSelect = useCallback(async () => {
    const payGroup = getPricesResponse?.paymentPrices.find((r) => r.paymentType === 'Gift Card');

    if (!payGroup) {
      return;
    }

    const [plan] = payGroup.subscriptionPlans || [];
    const { priceGroupProductID } = plan;
    const paymentOptionOverride = { name: payGroup?.paymentType, billerID: payGroup?.billerID };
    await onSelectPlan(priceGroupProductID, paymentOptionOverride);
  }, [onSelectPlan, getPricesResponse?.paymentPrices]);

  const chargeByPrevious = async (cartItemUUID?: string) => {
    setPurchasing(true);
    const storedData = getData<StoredOrderData>(cartStorageKey);

    const cartItem = storedData.cartItems?.find((item) => item.cartItemUUID === cartItemUUID) || ({} as CartItem);

    let body: Partial<{
      upgradeAborted: boolean;
      success: boolean;
      subscriptionID: string;
      iframeURL: string;
      redirectURL: string;
      postURL: string;
      postData: string;
    }> = {};
    try {
      const api = new ApiClient();
      ({ body } = await api.post(apiUrls.post.chargeByPrevious, {
        data: {
          cartItemUUID: cartItem?.cartItemUUID,
          billerID: storedData.billerID,
        },
      }));

      if (body.upgradeAborted) {
        addToast('Unable to upgrade subscription, please contact support', { appearance: 'error', autoDismiss: false });
        return;
      }

      if (body.success) {
        const type = paymentTypeForApi;

        const querystring = new URLSearchParams({
          billerID: `${storedData.billerID}`,
          cartItemUUID: cartItem?.cartItemUUID,
          subscriptionID: body.subscriptionID || '',
          type,
        });

        history.push(`${routerPaths.purchase.success}?${querystring.toString()}`);
        return;
      }

      if (body.iframeURL) {
        cartItem.iframeURL = body.iframeURL;
      }

      if (body.redirectURL) {
        cartItem.redirectURL = body.redirectURL;
      }

      if (body.postURL) {
        cartItem.postURL = body.postURL;
        cartItem.postData = body.postData || '';
        cartItem.redirectURL = '';
      }
    } catch (error) {
      //
    }

    if (!cartItem.iframeURL && !cartItem.redirectURL && !cartItem.postURL) {
      const message = !cartItem ? 'Missing cart data' : 'Missing form data';
      addToast(`Unable to complete purchase (${message}), please contact support`, {
        appearance: 'error',
        autoDismiss: false,
      });
      return;
    }
    setCanCBP(false);
    setPurchasing(false);
    showCartItem(cartItem);
  };

  if (redirecting) {
    return (
      <BackgroundContainer>
        <RedirectSection />
      </BackgroundContainer>
    );
  }

  return (
    <BackgroundContainer>
      <Header useAffiliateHeader />
      <div className="container">
        {isCascading && cascadeItem ? (
          <CascadeMessage cascadeCountDown={cascadeCountDown} />
        ) : (
          <>
            {iframeUrl && !canCBP && <PurchaseFrame iframeUrl={iframeUrl} validationRequired={validationRequired} />}
            {postURL && postData && <UpgradeFrame postURL={postURL} postData={postData} title="Complete Upgrade" />}
            {canCBP && !postURL && <ChargeByPrevious loading={purchasing} onClick={() => chargeByPrevious(itemUUID)} />}
            {!iframeUrl && !canCBP && !postURL && (
              <>
                <UserForm
                  disabled={user.isUser || purchasing}
                  email={[email, setEmail]}
                  emailError={[emailError, setEmailError]}
                  emailValidating={[emailValidating, setEmailValidating]}
                  password={[password, setPassword]}
                  passwordError={[passwordError, setPasswordError]}
                  showLoginModal={[showLoginModal, setShowLoginModal]}
                  renderEmailErrors={[renderEmailErrors, setRenderEmailErrors]}
                  renderPasswordErrors={[renderPasswordErrors, setRenderPasswordErrors]}
                />
                <PaymentMethods
                  activePayment={[activePayment, setActivePayment]}
                  getPricesResponse={getPricesResponse}
                  onGiftCardSelect={onGiftCardSelect}
                  purchasing={purchasing}
                />
                <SubscriptionPlans
                  activePayment={activePayment}
                  disabled={purchasing}
                  getPricesResponse={getPricesResponse}
                  onSelectPlan={onSelectPlan}
                  signalPayByCheck={() => props.signalSubEvent(ConversionTypes.PreJoinAlternativePaymentSubmit)}
                />
              </>
            )}
          </>
        )}

        <BottomPage>
          <StyledTeasers />
          <StyledFeatures featuresHeader={site.featuresHeader} />
          <StyledTestimonials />
        </BottomPage>
        <SecuritySection />
        <ChatWidget jira-id="9dab1c19-00bf-3be7-b9b6-14a91ae44549" service-desk-id="3" />
        <MainFooter />
      </div>
    </BackgroundContainer>
  );
};

const BackgroundContainer = styled.div`
  background-color: #fff;

  .container {
    padding: 14px 10px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 33px;
    @media ${mediaBreakpoints.md} {
      padding: 33px 0 0;
    }
  }
`;

const BottomPage = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;
  column-gap: 8px;
`;

const StyledTeasers = styled(Teasers)`
  grid-column: 1 / span 2;
`;

const bottomPageBox = css`
  border: 1px solid lightgray;
  height: 100%;
  grid-column: span 2;
  overflow: hidden;
  padding: 36px;

  @media screen and ${mediaBreakpoints.lg} {
    grid-column: span 1;
  }
`;

const StyledFeatures = styled(FeaturesList)`
  ${bottomPageBox}
`;

const StyledTestimonials = styled(Testimonials)`
  ${bottomPageBox}
`;

export default PreJoinPageRedesign;
