import { useEffect } from 'react';
import { Invoice } from '@tovia/man-protos/dist/types/billing.types';
import { useSuccessPageQueryParams } from 'src/client/pages/SuccessPage/Hooks/useSuccessPageQueryParams';
import { ApiClient, useAppData } from 'src/client/helpers';
import { apiUrls } from 'src/client/helpers/constants';
import { sendPurchaseGaData } from 'src/client/helpers/sendPurchaseGaData';
import { InvoiceGallery, OneClickVars } from 'src/client/pages/SuccessPage/SuccessPage';

export type UseHandlePurchaseValidationArgs = {
  setGallery: (gallery: InvoiceGallery) => void;
  setOneClickVars: React.Dispatch<React.SetStateAction<OneClickVars[]>>;
  setSuccess: (success: boolean) => void;
  setValidating: (validating: boolean) => void;
  validating: boolean;
};

export const useHandlePurchaseValidation = ({
  setGallery,
  setOneClickVars,
  setSuccess,
  setValidating,
  validating,
}: UseHandlePurchaseValidationArgs) => {
  const { networkSites, testBillers, testId, testPlatform, promotions } = useAppData();
  const { affiliateID, cartItemUUID, extSubscriptionID, galleryUUID, quality, mamPartnerID, extProductID, siteUUID } =
    useSuccessPageQueryParams();

  useEffect(() => {
    if (validating) {
      const api = new ApiClient();

      setTimeout(async () => {
        const cartItemUUIDs = cartItemUUID?.split(',');
        const extSubscriptionIDs = extSubscriptionID?.split(',');
        const siteUUIDs = siteUUID?.split(',');

        try {
          const promises = cartItemUUIDs?.map((cartItemUUID, index) =>
            api.get(apiUrls.get.invoice, {
              params: {
                cartItemUUID,
                extSubscriptionID: extSubscriptionIDs ? extSubscriptionIDs[index] : '',
              },
            }),
          );

          const responses = promises ? await Promise.all(promises) : [];

          const newOneClickVars: OneClickVars[] = [];

          responses.forEach((response, index) => {
            const { gallery: galleryItem, invoices } = response.body as {
              gallery: InvoiceGallery;
              invoices: Invoice[];
            };

            let mamID: string | undefined;
            let affiliateCode: string | undefined;

            if (mamPartnerID) {
              affiliateCode = `mam-${mamPartnerID}`;
              mamID = mamPartnerID;
            }

            // Only set the gallery if there is a gallery item in case of a PPS
            if (galleryItem) setGallery(galleryItem);

            newOneClickVars.push({
              billerId: invoices[0].billerID,
              extSubscriptionID: extSubscriptionIDs ? extSubscriptionIDs[index] : undefined,
              extProductID: extProductID || undefined,
              siteUUID: siteUUIDs ? siteUUIDs[index] : undefined,
              mamID,
              PA: affiliateID,
            });

            // Send Google Analytics data for each item
            sendPurchaseGaData({
              affiliateCode,
              cartItemUUID: cartItemUUID ? cartItemUUID[index] : '',
              galleryUUID,
              galleryItem,
              invoices,
              networkSites,
              promotions,
              quality,
            });
          });

          setOneClickVars(newOneClickVars);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Failed to process invoice data', error);
        }

        setSuccess(true);
        setValidating(false);
      }, 5000);
    }
  }, [
    affiliateID,
    cartItemUUID,
    extProductID,
    extSubscriptionID,
    galleryUUID,
    mamPartnerID,
    networkSites,
    promotions,
    promotions.promoCode,
    quality,
    setGallery,
    setOneClickVars,
    setSuccess,
    setValidating,
    siteUUID,
    testBillers,
    testId,
    testPlatform,
    validating,
  ]);
};
