import { useCallback } from 'react';
import { ABTestNames } from 'src/shared/types/ABTests';
import { useAppData } from './useAppData';

export const useGoogleAnalyticsABTest = (pageName: ABTestNames) => {
  const { abTests } = useAppData();
  const variant = abTests?.[pageName]?.variant;

  const pushGoogleAnalyticsEvent = useCallback(
    (category: string, value: string | null) => {
      if (variant) {
        window.dataLayer.push({
          event: 'gaEvent',
          eventCategory: category,
          eventLabel: {
            variant,
            value,
          },
        });
      }
    },
    [variant],
  );

  return pushGoogleAnalyticsEvent;
};
